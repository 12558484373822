import React from 'react';
import './styles/forside.css';
import logo from '../images/VoiceVictorLogo.png';
import demo1 from '../images/Side1.jpg';
import demo2 from '../images/Side2.jpg';

function Forside() {
  return (
    <div className="forside-container">
      <div className="development-banner">
        This product is under development
      </div>
      
      <header className="hero-section">
        <img src={logo} alt="Voice Victor Logo" className="header-logo" />
        <h1>Voice Victor</h1>
        <p className="intro-text">
          Voice Victor is your intelligent partner for voice analysis during phone calls. 
          The perfect tool for professionals who want to optimize their communication 
          and improve their results.
        </p>
      </header>

      <div className="content">
        <div className="features">
          <h2>Benefits of Voice Victor</h2>
          <div className="features-grid">
            <div className="feature-item">
              <div className="feature-icon">🎯</div>
              <h3>Real-time Analysis</h3>
              <p>Get instant voice analysis during your phone calls</p>
            </div>
            <div className="feature-item">
              <div className="feature-icon">📈</div>
              <h3>Better Results</h3>
              <p>Significantly improves customer service and sales performance</p>
            </div>
            <div className="feature-item">
              <div className="feature-icon">💡</div>
              <h3>Smart Feedback</h3>
              <p>Detailed feedback on your voice and communication</p>
            </div>
            <div className="feature-item">
              <div className="feature-icon">👥</div>
              <h3>Professional Tool</h3>
              <p>Ideal for sales representatives and customer service agents</p>
            </div>
            <div className="feature-item">
              <div className="feature-icon">🤖</div>
              <h3>AI-powered</h3>
              <p>Advanced artificial intelligence analyzes your conversations</p>
            </div>
            <div className="feature-item">
              <div className="feature-icon">⭐</div>
              <h3>Personal Growth</h3>
              <p>Tailored recommendations for your improvement</p>
            </div>
          </div>
        </div>

        <div className="demo-section">
          <h2>See how Voice Victor works</h2>
          <div className="demo-images">
            <div className="demo-card">
              <img src={demo1} alt="Voice Victor Demo 1" className="demo-img" />
              <p className="demo-caption">Real-time voice analysis dashboard</p>
            </div>
            <div className="demo-card">
              <img src={demo2} alt="Voice Victor Demo 2" className="demo-img" />
              <p className="demo-caption">Detailed conversation analysis</p>
            </div>
          </div>
        </div>

        <footer className="contact-section">
          <h2>Get in Touch</h2>
          <p className="contact-text">
            Interested in Voice Victor? Contact us to learn more.
          </p>
          <a href="mailto:oliver@voicevictor.com" className="contact-link">
            oliver@voicevictor.com
          </a>
        </footer>
      </div>
    </div>
  );
}

export default Forside;
